<template>
      <iframe id="ifrm" src="https://epsapp.prosperus.network/ayuda/index.php/chat/start" width="1000%" height="90%"></iframe>
</template>

<script>
export default {
    name: "MiAyuda",
    data:()=>({

    }),
    created(){
        this.$store.state.title="Ayuda"
     }

}
</script>

<style>

</style>