<template>
<div class="bg">
    <v-tabs v-model="tab" color="green" grow>
        <v-tab value="one">Ingresar</v-tab>
        <v-tab value="two">Crear cuenta</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
        <v-tab-item>
            <v-row align="center" justify="center">
                <v-card width="90%" elevation="0" class="pa-4 ma-2">
                    <v-form>
                        <v-text-field solo prepend-icon="mdi-email" label="E-mail" name="username" type="email" v-model="username" persistent-hint></v-text-field>
                        <v-text-field solo prepend-icon="mdi-lock" label="Contraseña" type="password" name="password" v-model="password"></v-text-field>
                        <v-card-actions>
                            <v-col>
                                <v-row justify="end">
                                    <v-btn :loading="logginin" @click=login() class="eps_green">Ingresar</v-btn>
                                </v-row>
                                <v-row justify="end">
                                    <v-btn @click="show_reset_password=true" text class="olvide" color="black">Olvidé mi contraseña</v-btn>
                                </v-row>
                            </v-col>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-row>
        </v-tab-item>
        <v-tab-item class="mt-2">
            <v-row align="center" justify="center">
                <v-card width="90%" elevation="0" class="pa-4 ma-2">
                    <v-form>
                        <v-text-field prepend-icon="mdi-email" label="E-mail" name="username" v-model="username"></v-text-field>
                        <v-text-field prepend-icon="mdi-lock" label="Contraseña" name="password" type="password" v-model="password"></v-text-field>
                        <v-text-field prepend-icon="mdi-lock" label="Confirmar contraseña" name="password" type="password" v-model="password_confirm"></v-text-field>
                        <v-divider></v-divider>
                        <v-text-field class="pl-8" prepend-icon="mdi-gift" label="Tengo un cupón" name="cupon" type="text" v-model="cupon"></v-text-field>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :loading="signingup" @click="signup()" class="eps_green">Crear cuenta...</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-row>
        </v-tab-item>
    </v-tabs-items>
    <v-row justify="center" class="mt-2" color="white">
        <v-card color="white">
            <v-btn href="https://www.facebook.com/epsmx" target="_blank" icon color="primary">
                <v-icon class="ma-2">mdi-facebook</v-icon>
            </v-btn>

            <v-btn href="https://bit.ly/EPS_CONTACTO" target="_blank" icon color="green">
                <v-icon class="ma-2">mdi-whatsapp</v-icon>
            </v-btn>
            <v-btn href="https://www.instagram.com/epsmx/?hl=es" target="_blank" icon color="purple">
                <v-icon class="ma-2">mdi-instagram</v-icon>
            </v-btn>
        </v-card>
    </v-row>
    <!-- Alerta -->
    <v-snackbar timeout="8000" v-model="alert">
        <v-container fluid class="pa-2 ma-2" :class="alert_color">{{alert_title}}</v-container>
        <v-container grid-list-xs>
            {{alert_message}}
        </v-container>
        <v-row justify="end">
            <v-btn text @click="alert_confirm()">Ok</v-btn>
        </v-row>
    </v-snackbar>
    <!-- Cambiar contraseña -->
    <v-dialog v-model="show_reset_password" scrollable :overlay="false" max-width="90%" transition="dialog-transition">
        <v-card>
            <v-card-title primary-title>
                Cambiar contraseña
            </v-card-title>
            <v-card-text>
                Confirma tu correo y haz click en "Cambiar contraseña", te enviaremos un correo con instrucciones.
            </v-card-text>
            <v-card-text>
                <v-text-field v-model="username" name="Correo" label="Usuario" id="id"></v-text-field>
            </v-card-text>
            <v-card-actions class="mb-6">
                <v-col>
                <v-row><p>{{recover_message}}</p></v-row>
                <v-row>
                    <v-spacer></v-spacer>
                <v-btn small @click="show_reset_password=false" class="eps_red">Cancelar</v-btn>
                <v-btn small @click="recover_password()" class="eps_green" dark>Cambiar contraseña...</v-btn>
                </v-row>
            </v-col>
            </v-card-actions>
        </v-card>

    </v-dialog>
</div>
</template>

<script>
import axios from 'axios'
export default {
    name: 'LoginSignup',
    data: () => ({
        recover_message: "",
        cupon: "",
        show_reset_password: false,
        tab: 0,
        apiurl: process.env.VUE_APP_ROOT_API,
        username: "",
        password: "",
        password_confirm: "",
        alert_message: "",
        email: "",
        signingup: false,
        logginin: false,
        alert: false,
        alert_color: "green",
        alert_title: "Atención"
    }),
    methods: {
        async signup() {
            this.signingup = true
            if (this.password != this.password_confirm) {
                this.alert_color = "red"
                this.alert_message = "Las contraseñas no coinciden"
                this.alert = true
                this.signingup = false
            } else if (this.password == "" || this.password_confirm == "") {
                this.alert_color = "orange"
                this.alert_message = "Los datos están incompletos"
                this.alert = true
                this.signingup = false
            } else if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.username)) {
                this.alert_message = "El correo no es válido"
                this.alert = true
                this.signingup = false
            } else {
                try {
                    let signup = await axios.post(this.apiurl + '/api/clients/signup', {
                        username: this.username,
                        password: this.password,
                        invitee_token: this.cupon
                    })
                    if (signup.data.status == "ok") {
                        this.alert_color = "blue"
                        this.alert_message = "Revisa tu correo para confirmar tu cuenta"
                        this.alert = true
                        this.signingup = false
                    } else if (signup.data.status == "error") {
                        this.alert_color = "red"
                        this.alert_message = signup.data.error_message
                        this.alert = true
                        this.signingup = false
                    }
                } catch (err) {
                    console.log(err)
                }
            }
        },
        async get_coupons_total(user_id) {
            try {
                let response = await axios.post(this.apiurl + '/api/coupons/get_coupons_total_by_user_id', {
                    user_id: user_id
                })
                if (response.data.status == "ok") {
                    this.$store.state.coupons_total = response.data.coupons[0].coupons_total
                }
            } catch (err) {
                console.log(err)
            }
            try {
                let response = await axios.post(this.apiurl + '/api/coupons/get_coupons_by_user_id', {
                    user_id: user_id
                })
                if (response.data.status == "ok") {
                    this.$store.state.coupons = response.data.coupons
                }
            } catch (err) {
                console.log(err)
            }
        },
        async login() {
            this.login_message = ""
            this.logginin = true
            try {
                let login = await axios.post(this.apiurl + '/api/clients/login', {
                    username: this.username,
                    password: this.password
                })
                if (login.data.status == "ok") {
                    this.$store.state.menu_inferior = 0
                    this.$store.state.user = login.data.user
                    localStorage.setItem('user', JSON.stringify(login.data.user));
                    this.get_coupons_total(this.$store.state.user.id)
                    this.$store.state.loggedin = true
                    localStorage.setItem('loggedin', true);
                    this.logginin = false
                } else if (login.data.status == "pending") {
                    this.alert = true
                    this.alert_color = "orange"
                    this.alert_message = login.data.message
                    this.logginin = false
                } else if (login.data.status == "deleted") {
                    this.alert = true
                    this.alert_color = "red"
                    this.alert_message = login.data.message
                    this.logginin = false
                } else if (login.data.status == "suspended") {
                    this.alert_color = "orange"
                    this.alert = true
                    this.alert_message = login.data.message
                    this.logginin = false
                } else if (login.data.status == "not found") {
                    this.alert_color = "red"
                    this.alert = true
                    this.alert_message = login.data.message
                    this.logginin = false
                }
                if (login.data.status == "error") {
                    this.alert_color = "red"
                    this.alert = true
                    this.alert_message = login.data.message
                    this.logginin = false
                }
            } catch (err) {
                console.log(err)
            }
        },
        async confirm_account(token) {
            let confirmed = await axios.post(this.apiurl + '/api/clients/confirm_account', {
                token: token,
            })
            return confirmed
        },
        async recover_password() {
            let recover = await axios.post(this.apiurl + '/api/clients/recover_password', {
                username: this.username,
            })
            if (recover.data.status == "ok") {
                this.alert = true
                this.alert_message = "Te enviamos un correo con instrucciones para reestablecer tu contraseña..."
                this.show_reset_password = false
            } else if (recover.data.status == "error") {
                this.recover_message = recover.data.message
            }
        },

        alert_confirm() {
            this.alert = false
            return true
        },
    },
    computed: {
        loggedin() {
            if (localStorage.getItem("loggedin")) {
                const data = localStorage.getItem("loggedin")
                return data
            } else {
                return false
            }
        }
    },
    async created() {
        this.cupon = this.$store.state.cupon
        if (this.cupon != "") {
            this.tab = 1
        }
        this.$store.state.title = "MiEPS+"
        this.$store.state.menu_inferior = 0
        this.logginin = false
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        const origin = urlParams.get('origin')
        let cupon = ""
        cupon = urlParams.get('cupon')
        if (cupon != "") {
            this.cupon = cupon
        }
        const token = urlParams.get('token')
        if (localStorage.getItem("loggedin")) {
            this.$store.state.user = JSON.parse(localStorage.getItem("user"))
            this.$store.state.user_vehicles = JSON.parse(localStorage.getItem("vehicles"))
            this.$store.state.loggedin = true
        }
        if (token) {
            let response = await this.confirm_account(token)
            if (response.data.status == "ok") {
                this.alert = true
                this.alert_message = "Tu cuenta ha sido confirmada"
                this.alert_color = "green"
            }
        }
        if (origin == "app") {
            //if login
            this.app = true
            this.browser = false
        } else {
            this.app = false
            this.browser = true
        }
    }
};
</script>

<style scoped>
.boton_eps {
    font-size: .8em !important;
    padding: 2 !important;

}

.v-text-field {
    height: 52px;
    color: rgb(0, 0, 0);
    margin: 5;
    padding: 5;
}

.olvide {
    font-size: .7em !important;
    padding: 0 !important;
}

.my-overlay {
    width: 100%;
    height: 100%;
}

.bg {
    height: 100vmin;
    background: url('../assets/back01.png') no-repeat center center;
    background-size: 100% 100%;
    background-position: top;
}
</style>
