<template>
<div>
    <v-row justify="center" class="ma-4">
        <h1>Qué es MiEPS+</h1>
    </v-row>
    <v-row justify="center">
        <h2>Descarga la app</h2>
    </v-row>
    <v-row justify="center">
        <v-icon size="100" class="ma-6">mdi-apple</v-icon>
        <v-icon size="100" class="ma-6">mdi-android</v-icon>
    </v-row>
    <v-row justify="center">
        <h2>Beneficios</h2>
    </v-row>
</div>
</template>

<script>
export default {
    name: 'HomePage',

    data: () => ({

    }),
    mounted() {

    },

    methods: {

    },
};
</script>

<style scoped>

</style>
