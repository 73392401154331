import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import { supabase } from './lib/supabaseClient'
import VueCountryCode from "vue-country-code";
Vue.use(VueCountryCode);

// Filters
Vue.filter('capitalize', function(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('date_format_time', function(value) {
    var options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: 'Europe/Berlin'
    };
    var d = new Date(value)
    return d.toLocaleDateString('es-MX', options)
})
Vue.filter('date_format_time_mx', function(value) {
    var options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        timeZone: 'America/Mexico_City'
    };
    var d = new Date(value)
    return d.toLocaleDateString('es-MX', options)
})
Vue.filter('days_until', function(value) {

    var d = new Date(value)
    let difference = Date.now() - d;
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24)) * -1;
    if (TotalDays >= 0) {
        return TotalDays
    } else {
        return 0
    }

})
Vue.filter('date_format', function(value) {
    var options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour12: true,
        timeZone: 'Europe/Berlin'
    };
    var d = new Date(value)
    return d.toLocaleDateString('es-MX', options)
})
Vue.filter('time', function(value) {
    return value.slice(0, -3);
})
Vue.filter('date_format_short', function(value) {
    var options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'Europe/Berlin'
    };
    var d = new Date(value)
    return d.toLocaleString('es-MX', options)
})
Vue.filter('MXN', function(value) {
    let pesos = Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
    })
    return pesos.format(value / 100)
})
Vue.filter('MXN_no_cents', function(value) {
    let pesos = Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
        minimumFractionDigits: 0,
    })
    return pesos.format(value/100)
})

// Mixins
Vue.mixin({
    methods: {
        async supabase_upload(name, file) {
            const upload_file = file
            const {
                data,
                error
            } = await supabase
                .storage
                .from('MiEPS')
                .upload('vehicle_pics/' + name, upload_file, {
                    cacheControl: '3600',
                    upsert: false
                })
                console.log(data, error)
        },
    },
  })

Vue.config.productionTip = false
new Vue({
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app')